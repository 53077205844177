import placeholder from '../../../images/slides/aboutus/placeholder.png';
import prezes from '../../../images/slides/aboutus/prezes.png';
import karolina from '../../../images/slides/aboutus/karolina.png';
import tomek from '../../../images/slides/aboutus/tomek.png';
import rafal from '../../../images/slides/aboutus/rafal.png';
import aga from '../../../images/slides/aboutus/aga.png';
import kuba from '../../../images/slides/aboutus/kuba.png';
import michalg from '../../../images/slides/aboutus/michalg.png';
import filip from '../../../images/slides/aboutus/filip.png';
import michals from '../../../images/slides/aboutus/michals.png';
import piotrek from '../../../images/slides/aboutus/piotrek.png';
import krystian from '../../../images/slides/aboutus/krystian.png';
import macieks from '../../../images/slides/aboutus/macieks.png';
import daniel from '../../../images/slides/aboutus/daniel.png';
import aniaf from '../../../images/slides/aboutus/aniaf.png';
import kasia from '../../../images/slides/aboutus/kasia.png';
import marysia from '../../../images/slides/aboutus/marysia.png';

const allPhotos = [
  { image: prezes, name: 'Maciej Góralski', position: 'Founder and CEO of HORSEmanago' },
  { image: karolina, name: 'Karolina Szulc', position: 'Marketing Specialist' },
  { image: tomek, name: 'Tomasz Stomski', position: 'Technical Director' },
  { image: kasia, name: 'Katarzyna Kaproń', position: 'Product Owner' },
  { image: rafal, name: 'Rafał Rojowski', position: 'Tech Lead, Android Developer' },
  { image: aga, name: 'Agnieszka Müller', position: 'Test Lead, Senior Software Tester' },
  { image: kuba, name: 'Jakub Dziadek', position: 'Tech Lead, Full Stack Developer' },
  { image: michals, name: 'Michał Szota', position: 'Senior Backend Developer' },
  { image: placeholder, name: 'Ewelina Kurek', position: 'Business Analyst' },
  { image: michalg, name: 'Michał Gawlik', position: 'Frontend Developer' },
  { image: filip, name: 'Filip Gędłek', position: 'Frontend Developer' },
  { image: piotrek, name: 'Piotr Bracha', position: 'DevOps' },
  { image: krystian, name: 'Krystian Wierzbic', position: 'Android Developer' },
  { image: macieks, name: 'Maciej Smolik', position: 'Android Developer' },
  { image: placeholder, name: 'Katarzyna Gędłek', position: 'Android Developer' },
  { image: daniel, name: 'Daniel Kilijanek', position: 'Android Developer' },
  { image: aniaf, name: 'Anna Firlińska', position: 'UX / UI Designer' },
  { image: placeholder, name: 'Karolina Niedziela', position: 'UX / UI Designer' },
  { image: placeholder, name: 'Beata Sagan', position: 'UX / UI Designer' },
  { image: marysia, name: 'Maria Zięba', position: 'Software Tester' },
  { image: placeholder, name: 'Damian Otrębski', position: 'Software Tester' },
];

export default allPhotos;
