import React from 'react';
import PropTypes from 'prop-types';

const ContentTitle = ({ children, content, ...restProps }) => (
  <h3 className="content-title" {...restProps}>
    {children || content}
  </h3>
);

ContentTitle.defaultProps = {
  children: null,
  content: null,
};

ContentTitle.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string,
};

export default ContentTitle;
