import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setIsMobile, setIsTablet } from '../store/app';
import Section from '../components/shared/section';
import Heading from '../components/shared/heading';
import ContentTitle from '../components/shared/content-title';
import ContentBlock from '../components/shared/content-block';
import SliderTeam from '../components/shared/slider/slider-small';
import t from '../locales';
import { setPage } from '../store/app';
import TeamPhotos from '../components/shared/slider/team-photos';

class About extends Component {
  constructor(props) {
    super(props);
    props.setPage('about');
  }
  render() {
    return (
      <>
        <Section id="banner" className="bg-black about">
          <div className="flex">
            <div className="self-center" style={{ paddingBottom: '60px' }}>
              <h1 className="text-teal shadow-text-black" style={{ fontSize: '2rem' }}>
                <div>{t('about.theBestSystem')}</div>
                <div>{t('about.allowingComprehensiveManagement')}</div>
                <div>{t('about.anyEquestrianCenterOnline')}</div>
              </h1>
            </div>
          </div>
        </Section>
        <Heading inverted> {t('title.about')} </Heading>
        <Section>
          <ContentTitle>{t('about.weAreVascoElectronics.title')}</ContentTitle>
          <ContentBlock image="HM-img-04.jpg">
            <p>{t('about.weAreVascoElectronics.content1')}</p>
            <p>{t('about.weAreVascoElectronics.content2')}</p>
            <p>{t('about.weAreVascoElectronics.content3')}</p>
          </ContentBlock>
        </Section>
        <Section className="bg-grey-darkest" alt="HORSEmanago" image="HM-img-05.jpg" inverted>
          <ContentTitle>{t('about.ourAdventure.title')}</ContentTitle>
          <p>{t('about.ourAdventure.content1')}</p>
          <p>{t('about.ourAdventure.content2')}</p>
          <p>{t('about.ourAdventure.content3')}</p>
        </Section>
        <Section className="slider-section bg-white">
          <ContentTitle>{t('about.ourTeam.title')}</ContentTitle>
          <SliderTeam sliderImages={TeamPhotos} />
        </Section>
      </>
    );
  }
}

export default connect(
  state => ({
    pageName: state.app.pageName,
    langKey: state.app.langKey,
    isMobile: state.app.isMobile,
    isTablet: state.app.isTablet,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
    setIsMobile: isMobile => dispatch(setIsMobile(isMobile)),
    setIsTablet: isTablet => dispatch(setIsTablet(isTablet)),
  })
)(About);
