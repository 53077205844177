import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Image from '../image';

const ContentBlock = ({ children, image, className, position, alt, content, ...restProps }) => (
  <div className={classnames('content-block', position === 'right' && 'flex-row-reverse', className)} {...restProps}>
    {image && (
      <div>
        <Image src={image} alt={alt} />
      </div>
    )}
    <div> {children || content} </div>
  </div>
);

ContentBlock.defaultProps = {
  className: null,
  image: null,
  children: null,
  content: null,
  position: 'left',
  alt: null,
};

ContentBlock.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.string,
  position: PropTypes.string,
  alt: PropTypes.string,
};

export default ContentBlock;
